export const VertexShader = `
    #define PI 3.1415926535897932384626433832795
    attribute float size;
    void main() {
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_PointSize = 2.5;
        gl_Position = projectionMatrix * mvPosition;
    }
`;

export const FragmentShader = `
    uniform sampler2D texture;
    void main(){
    	vec4 textureColor = texture2D( texture, gl_PointCoord );
    	if ( textureColor.a < 0.3 ) discard;
    	vec4 dotColor = vec4(0.98, 0.85, 0.35, 0.2);
    	vec4 color = dotColor * textureColor;
    	gl_FragColor = color;
    }
`;