import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description:
    'Browse cocktail recipes. Learn how to mix drinks. For bartenders and home bar',
  title: 'Jigger: Cocktail Drink Recipes',
  url: 'https://makeitjigger.app',
  author: 'Oleh Zasadnyy',
  keywords: [
    'jigger',
    'cocktails',
    'mixology',
    'app',
    'bartender',
    'mixed drinks',
    'classic cocktails',
    'home bar',
    'old fashioned',
    'vodka drinks',
    'iba',
    'sour',
  ],
  twitter: {
    id: '@makeitjigger',
    img: 'https://makeitjigger.app/twitter.png',
  },
  facebook: {
    id: '448297412515324',
    img: 'https://makeitjigger.app/facebook.png',
  },
  color: '#161e2d',
  appScheme: 'jigger://',
  android: {
    package: 'com.zasadnyy.jigger',
    url: 'https://play.google.com/store/apps/details?id=com.zasadnyy.jigger',
  },
  ios: {
    id: 1497308666
  },
};

const SEO = () => {
  return (
    <Helmet>
      <title>{SEO_DATA.title}</title>
      <html lang="en" />
      <meta name="theme-color" content={SEO_DATA.color} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1"
      />
      <meta property="fb:app_id" content={SEO_DATA.facebook.id} />
      <meta property="al:ios:url" content={SEO_DATA.appScheme} />
      <meta property="al:ios:app_store_id" content={SEO_DATA.ios.id} />
      <meta property="al:ios:app_name" content={SEO_DATA.title} />
      <meta property="al:android:url" content={SEO_DATA.appScheme} />
      <meta property="al:android:package" content={SEO_DATA.android.package} />
      <meta property="al:android:app_name" content={SEO_DATA.title} />
      <meta property="al:web:should_fallback" content="true" />
      <meta property="al:web:url" content={SEO_DATA.url} />
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.facebook.img} />
      <meta property="og:description" content={SEO_DATA.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={SEO_DATA.twitter.id} />
      <meta name="twitter:site" content={SEO_DATA.twitter.id} />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.img} />
      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
    </Helmet>
  );
};

export default SEO;
