import React from 'react';
import {
    WebGLRenderer,
    Scene,
    PerspectiveCamera,
    TextureLoader,
    IcosahedronGeometry,
    Geometry,
    BufferGeometry,
    BufferAttribute,
    ShaderMaterial,
    Points,
    Vector2
} from 'three';
import {gsap, TweenMax, Back, Power1} from "gsap";

import dotTextureImage from '@images/dotTexture.png';
import {FragmentShader, VertexShader} from './shaders';
import Canvas from './Canvas';

const RADIUS = 60;

class Background extends React.PureComponent {
    canvas = React.createRef();

    constructor(props) {
        super(props);
        this.resizeTm = null;
        this.mouse = new Vector2(0.8, 0.5);
        this.scene = new Scene();
    }

    componentDidMount() {
        this.initBackground();

        gsap.ticker.add(this.renderScene);
        window.addEventListener("mousemove", this.onMouseMove);
        window.addEventListener("resize", () => {
            this.resizeTm = clearTimeout(this.resizeTm);
            this.resizeTm = setTimeout(() => this.onResize(), 200);
        });
    }

    onMouseMove = (e) => {
        this.mouse.x = (e.clientX / window.innerWidth) - 0.5;
        this.mouse.y = (e.clientY / window.innerHeight) - 0.5;
        TweenMax.to(this.dots.rotation, 20, {
            x: (this.mouse.y * Math.PI * 0.5),
            z: (this.mouse.x * Math.PI * 0.2),
            ease: Power1.easeOut
        });
    };

    onResize = () => {
        this.canvas.current.style.width = '';
        this.canvas.current.style.height = '';
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
    };

    initBackground = () => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.renderer = new WebGLRenderer({
            canvas: this.canvas.current,
            antialias: true
        });
        this.renderer.setPixelRatio(window.devicePixelRatio > 1 ? 2 : 1);
        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(0xff161E2D);

        this.camera = new PerspectiveCamera(50, this.width / this.height, 0.1, 2000);
        this.camera.position.set(10, 15, 80);

        const loader = new TextureLoader();
        loader.crossOrigin = "Anonymous";
        const dotTexture = loader.load(dotTextureImage);

        const sphereGeom = new IcosahedronGeometry(RADIUS, 5);
        const dotsGeom = new Geometry();
        const bufferDotsGeom = new BufferGeometry();
        this.positions = new Float32Array(sphereGeom.vertices.length * 3);
        for (let i = 0; i < sphereGeom.vertices.length; i++) {
            let vector = sphereGeom.vertices[i];
            this.animateDot(i, vector);
            dotsGeom.vertices.push(vector);
            vector.toArray(this.positions, i * 3);
        }

        const attributePositions = new BufferAttribute(this.positions, 3);
        bufferDotsGeom.setAttribute('position', attributePositions);
        const shaderMaterial = new ShaderMaterial({
            uniforms: {
                texture: {
                    value: dotTexture
                }
            },
            vertexShader: VertexShader,
            fragmentShader: FragmentShader,
            transparent: true
        });

        this.dots = new Points(bufferDotsGeom, shaderMaterial);
        this.scene.add(this.dots);
    };

    animateDot = (index, vector) => {
        TweenMax.to(vector, 7, {
            x: vector.x * 0.95,
            y: vector.y * 0.7,
            z: vector.z * 0.4,
            ease: Back.easeOut,
            delay: Math.abs(vector.y / RADIUS) * 2,
            repeat: -1,
            yoyo: true,
            yoyoEase: Back.easeOut,
            onUpdate: () => {
                this.updateDot(index, vector);
            }
        });
    };

    updateDot = (index, vector) => {
        this.positions[index * 3] = vector.x;
        this.positions[index * 3 + 2] = vector.z;
    };

    renderScene = () => {
        this.dots.geometry.verticesNeedUpdate = true;
        this.dots.geometry.attributes.position.needsUpdate = true;
        this.renderer.render(this.scene, this.camera);
    };

    render() {
        return (
            <>
                <Canvas ref={this.canvas}/>
                {this.props.children}
            </>
        );
    }
}

export default Background;
