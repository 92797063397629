import styled from 'styled-components';

export const TextContainer = styled.div`
  padding: 120px 40px 64px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const TextContent = styled.div`
  width: 100%;
  max-width: 640px;
  color: white;
  
  p {
    margin-bottom: 32px;
    font-size: 18px;
  }
  
  ol {
    font-size: 18px;
  }
`;
